import * as React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import MarchMp3 from "../assets/march.mp3";
import GalleryLightbox from "../components/GalleryLightbox";
import AmpLogo from "../inline_svg/amp-logo.svg";
import CalendarPlusLight from "../inline_svg/calendar-plus-light.svg";
import CalendarStarLight from "../inline_svg/calendar-star-light.svg";
import DateLogo from "../inline_svg/date-logo.svg";
import FilmLight from "../inline_svg/film-light.svg";
import GiftLight from "../inline_svg/gift-light.svg";
import HeadSideMaskLight from "../inline_svg/head-side-mask-light.svg";
import ImagesLight from "../inline_svg/images-light.svg";
import MapMarkedAltLight from "../inline_svg/map-marked-alt-light.svg";
import MapMarkerAltLight from "../inline_svg/map-marker-alt-light.svg";
import MusicLight from "../inline_svg/music-light.svg";
import RingsWeddingLight from "../inline_svg/rings-wedding-light.svg";
import UserTieLight from "../inline_svg/user-tie-light.svg";
import WazeBrands from "../inline_svg/waze-brands.svg";
import "../styles/index.scss";

const IndexPage = () => {
  return (
    <main>
      <section className="hero">
        <Container className="date">
          <Row>
            <Col xs="12">
              <DateLogo />
            </Col>
          </Row>
        </Container>
        <Container className="names">
          <Row>
            <Col xs="12">
              <h2>Gereve Danielle Mangahas</h2>
              <AmpLogo />
              <h2>Mark Joshua Tan</h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="event" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12">
              <h3 className="icon-header">
                <CalendarStarLight />
              </h3>
              <p>Tuesday the Sixteenth of March</p>
              <p>Two Thousand and Twenty-one</p>
              <p>Four O'Clock in the Afternoon</p>
              <p>
                <Button variant="light" href="event.ics" download>
                  <CalendarPlusLight /> Add to calendar
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="map" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="8">
              <h3 className="icon-header">
                <MapMarkedAltLight />
              </h3>
              <p>Casa Buenas, Hotel Okura, Pasay City</p>
              <p>Parking available</p>
              <p>
                <Button
                  variant="light"
                  href="https://maps.app.goo.gl/YMAmrCMeNuC5BtSCA"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <MapMarkerAltLight /> Drive with google
                </Button>
              </p>
              <p>
                <Button
                  variant="light"
                  href="https://waze.com/ul/hwdw49g4tc"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <WazeBrands /> Drive with waze
                </Button>
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3862.394373113675!2d121.01571561483937!3d14.519420189854294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397cf424bf21fe7%3A0x727ea3c9b545b9a2!2sCasa%20Buenas!5e0!3m2!1sen!2sph!4v1612699285697!5m2!1sen!2sph"
                title="map"
                className="map"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
                aria-hidden="false"
                loading="lazy"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="video" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="8">
              <h3 className="icon-header">
                <FilmLight />
              </h3>
              <p>The following videos are now private</p>
              <p>If you wish to view them</p>
              <p>You may request access from Mark</p>
              <br />
              <h4>SDE</h4>
              <div className="youtube-embed-container">
                <iframe
                  className="youtube-embed"
                  src="https://www.youtube.com/embed/8zDJJ_fIEpA"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="sde"
                  loading="lazy"
                />
              </div>
              <h4>Live Stream</h4>
              <div className="youtube-embed-container">
                <iframe
                  className="youtube-embed"
                  src="https://www.youtube.com/embed/Gg8kdjeoC9M"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="livestream"
                  loading="lazy"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="audio" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="8">
              <h3 className="icon-header">
                <MusicLight />
              </h3>
              <h4>Bridal March</h4>
              <p>
                <small>Grow Old With Me - John Lennon</small>
              </p>
              <audio
                controls
                controlsList="nodownload"
                className="audio-container"
                preload="metadata"
                loop={false}
              >
                <source src={MarchMp3} type="audio/mpeg" />
              </audio>
              <h4>Reception</h4>
              <iframe
                className="audio-container"
                src="https://open.spotify.com/embed/playlist/7kWJW73dvogaHvgIk8m2jY"
                width="300"
                height="380"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
                title="spotify-embed"
                loading="lazy"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="images" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12">
              <h3 className="icon-header">
                <ImagesLight />
              </h3>
              <GalleryLightbox className="gallery-lightbox" />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="attire" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12">
              <h3 className="icon-header">
                <UserTieLight />
              </h3>
              <p>Cocktail Attire</p>
              <p>Cocktail Dress for Ladies</p>
              <p>Dark Suit and Tie for Gentlemen</p>
              <p>Avoid black or white suits and dressess</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="intimate" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12">
              <h3 className="icon-header">
                <RingsWeddingLight />
              </h3>
              <p>Our wedding celebration is an intimate one</p>
              <p>At a time of heightened safety measures</p>
              <p>Should you be unable to attend our wedding</p>
              <p>
                A <a href="#video">video stream</a> will be provided
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="gifts" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12">
              <h3 className="icon-header">
                <GiftLight />
              </h3>
              <p>Should you insist that a gift is worth giving</p>
              <p>A small amount for our future is very welcome</p>
              <p>If you are unable to attend the celebration</p>
              <p>Please message us for details</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="safety" className="details">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12">
              <h3 className="icon-header">
                <HeadSideMaskLight />
              </h3>
              <p>Please observe social distancing measures</p>
              <p>Masks and face shields are required</p>
              <p>We request that you self-quarantine</p>
              <p>A few days to a week before the event</p>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default IndexPage;
