import { graphql, useStaticQuery } from "gatsby";
import React, { useCallback, useMemo, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";

const GalleryLightbox = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid {
                srcSetWebp
                srcWebp
                sizes
                presentationWidth
                presentationHeight
                originalImg
              }
            }
          }
        }
      }
    }
  `);
  const [currentImage, setCurrentImage] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const handleOpenLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setIsLightboxOpen(true);
  }, []);

  const handleCloseLightbox = useCallback(() => {
    setIsLightboxOpen(false);
  }, []);

  const getColumnCount = useCallback((containerWidth) => {
    return Math.max(2, Math.floor(containerWidth / 200));
  }, []);

  const galleryPhotos = useMemo(
    () =>
      data.allFile.edges.map(({ node: { name, childImageSharp } }) => ({
        src: childImageSharp.fluid.srcWebp,
        width: childImageSharp.fluid.presentationWidth,
        height: childImageSharp.fluid.presentationHeight,
        sizes: childImageSharp.fluid.sizes,
        srcSet: childImageSharp.fluid.srcSetWebp,
        srcset: childImageSharp.fluid.srcSetWebp,
        alt: name,
        title: name,
        caption: name,
        source: {
          download: childImageSharp.fluid.originalImg,
          regular: childImageSharp.fluid.originalImg,
          fullscreen: childImageSharp.fluid.originalImg,
          thumbnail: childImageSharp.fluid.srcWebp,
        },
      })),
    [data]
  );

  return galleryPhotos.length ? (
    <div className={className}>
      <Gallery
        photos={galleryPhotos}
        onClick={handleOpenLightbox}
        direction="column"
        columns={getColumnCount}
      />
      <ModalGateway>
        {isLightboxOpen && (
          <Modal onClose={handleCloseLightbox}>
            <Carousel currentIndex={currentImage} views={galleryPhotos} />
          </Modal>
        )}
      </ModalGateway>
    </div>
  ) : (
    <p>Image gallery coming soon</p>
  );
};

export default GalleryLightbox;
